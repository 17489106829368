import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React from "react";
import { EffectFade, Navigation, Pagination } from "swiper/modules";

export const MbSlide = ({ className, images }) => {
    const cssNames = [
        {
            classText1:"text-3xl font-gmr my-4",
            classText2: "",
            classText3:"",
            classHelp: "mt-10 inline-flex w-7/12 text-center ",
        },
        {
            classText1:"text-2xl text-quater font-gmr text-left ml-20 ",
            classText2:"text-2xl w-7/12 text-primary text-left ml-20",
            classText3:"font-gmr text-left text-quater  font-light ",
            classHelp:"mt-10 w-8/12 text-quater font-bold ml-20 "
        },
        {
            classText1:"text-2xl text-quater font-gmr text-left  ml-20  ",
            classText2:"text-2xl w-7/12 text-white text-left ml-20 ",
            classText3:"text-sm font-gmr sm:text-md md:text-lg lg:text-1xl xl:text-3xl text-left text-quater  font-light  ",
            classHelp: "mt-10 w-8/12 text-quater  ml-20"
        },
        {
            classText1:"text-2xl text-quater font-gmr text-left  ml-20  mt-20 ",
            classText2:"text-2xl w-7/12 text-white text-left ml-20 ",
            classText3:"text-sm font-gmr sm:text-md md:text-lg lg:text-1xl xl:text-3xl text-left text-quater text-white  font-light  ",
            classHelp: "mt-10 w-8/12 text-quater ml-20"
        },
    ];
    if(!images[0])
        return <span className={`antialiased font-bold text-lg bg-gray-300 animate-pulse `}>
            &nbsp; Loading...
        </span>
    return (
        <div className={className}>
            <Swiper
                spaceBetween={10}
                effect={"fade"}
                navigation={true}                
                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper"
            >
                {images.map((image,index) => (
                    <SwiperSlide key={index}>
                        <div className="flex items-center align-middle justify-center">
                            <img className=" object-cover w-screen h-screen"  src={image.mv} alt={image.text}/>
                            <div className="absolute text-white w-full text-4xl text-center font-gmr mt-20 ">
                            {
                                image.logo?(
                                <div className=" flex w-full items-center justify-center -mt-20 ">
                                    <img className="w-52 h-auto items-center"  src={image.logo} alt={image.text}/>
                                </div>
                            ):(
                                <h1 className={cssNames[index].classText1}>{image.text}</h1>
                                )
                            }                           
                                <h2 className={cssNames[index].classText2}>{image.text1}</h2>
                                <div className={cssNames[index].classHelp}>
                                    <h3 className={`${cssNames[index].classText3} font-gmr w-full text-base`}>{image.description}</h3>
                                </div>
                            </div>
                            {
                                image.powered?(
                                <div className="absolute flex top-3/4 h-1/6 w-full items-center " >
                                    <h2 className={"text-sm text-quater font-gmr sm:text-lg md:text-md lg:text-xl xl:text-3xl text-left  ml-24 my-2 "}>{image.powerText}</h2>
                                    <img className="w-12 h-12"  src={image.powered} alt={image.text}/>
                                </div>):(<div></div>)
                            }
                        </div>                  
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
