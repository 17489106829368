import { useContext } from "react";
import { LangContext } from "../context/LangContext";
import { PcSlide } from "./sliders/PcSlide";
import { MbSlide } from "./sliders/MbSlide";



export const Home = () => {
    const [language] = useContext(LangContext);    
    let imgs = [];
    for(const images in language.homeSlide){
        imgs.push(language.homeSlide[images]);
    }
    return (
        <>        
           <PcSlide className={"hidden md:block"} images={imgs} />
           <MbSlide className={"block md:hidden -mt-10"} images={imgs} />
        </>
    );
};
