import { useContext } from 'react';
// import back from '../images/Section3/back.jpg';
import c1 from '../images/Section3/c1.jpg';
import p1 from '../images/Section3/p1.jpg';
import { LangContext } from '../context/LangContext';

export const AboutUs = () => {

  const [language] = useContext(LangContext);
  return (
    <>
    <div className="flex flex-col">
            <div className="">
                <img src={c1} className="object-cover w-screen block md:hidden" alt=''/>
                <img src={p1} className="object-cover w-screen hidden md:block lg:hidden" alt=''/>
                
                
                <div className="hidden lg:flex bg-cover bg-center bg-[url('./images/Section3/back.jpg')]">

                    {/* <img src={back} className="object-cover w-screen h-screen hidden lg:block" alt=''/> */}

                    <div className='flex flex-col h-96 lg:h-full min-h-screen w-screen justify-evenly pt-16'>

                        <div className='w-auto lg:w-1/2 px-10 md:px-16 lg:px-24'>
                            <p className='text-quater font-gmr font-bold
                            text-lg sm:text-lg md:text-xl lg:text-3xl'>{ language.secc3text1 }</p>
                        </div>
                        
                        <div className='w-auto lg:w-1/2 pl-24'>
                            <p className='font-raleway text-senar
                            text-xs sm:text-sm md:text-base lg:text-lg'>{ language.secc3text2 }</p>
                        </div>
                        <div className='w-auto lg:w-1/2 pl-24'>
                            <p className='font-raleway text-senar
                            text-xs sm:text-sm md:text-base lg:text-lg'>{ language.secc3text3 }</p>
                        </div>

                        <div className='flex justify-end mx-24'>
                            <button className="font-ddin text-white transition-colors duration-150 bg-quater rounded-[30px] 
                            hover:border-2 hover:border-quater hover:bg-opacity-0 hover:text-black
                                    lg:text-lg text-base
                                    xl:w-44 lg:w-52 md:w-52 sm:w-44 w-32
                                    xl:h-10 lg:h-10 md:h-10 sm:h-10 h-8
                                ">
                                { language.secc5lang1 }
                            </button>
                        </div>
                    </div>
                </div>   
            </div>


            <div className="bg-cover bg-center h-full py-10 block lg:hidden
                bg-[url('./images/Section3/c2.jpg')] md:bg-[url('./images/Section3/p2.jpg')]">
                    <div className='px-10 md:px-16'>
                        <p className='text-quater font-gmr font-bold
                        text-lg sm:text-lg md:text-xl lg:text-3xl'>{ language.secc3text1 }</p>
                    </div>

                    <div className='px-10 md:px-16 my-10'>
                        <p className='font-raleway text-senar
                        text-xs sm:text-sm md:text-base lg:text-lg'>{ language.secc3text2 }</p>
                    </div>
                    <div className='px-10 md:px-16 my-10'>
                        <p className='font-raleway text-senar
                        text-xs sm:text-sm md:text-base lg:text-lg'>{ language.secc3text3 }</p>
                    </div>

                    <div className='flex justify-end px-10 md:px-16'>
                            <button className="font-raleway text-white bg-quater rounded-[30px] 
                            hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                    lg:text-xs md:text-base text-xs
                                    xl:w-44 md:w-52 sm:w-44 w-32
                                    xl:h-10 md:h-10 sm:h-10 h-8
                                ">
                                { language.secc5lang1 }
                            </button>
                    </div>
            </div>
        </div>
    </>
  )
}
