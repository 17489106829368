import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { ImageSlide } from "./ImageSlide";

export const PcSlide = ({ className, images }) => {
    // const cssNames = [
    //     {
    //         classText1:"text-sm font-gmr sm:text-lg md:text-xl lg:text-3xl xl:text-5xl my-4",
    //         classText2: "",
    //         classText3:"text-sm font-gmr sm:text-md md:text-lg lg:text-2xl xl:text-3xl w-full ",
    //         classHelp: "mt-10 inline-flex w-6/12 text-center ",
    //     },
    //     {
    //         classText1:"text-quater font-gmr text-left ml-24 my-2 text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-5xl",
    //         classText2:"w-6/12 text-primary text-left sm:text-lg md:text-xl lg:text-2xl xl:text-5xl ml-24  ",
    //         classText3:"text-sm font-gmr sm:text-md md:text-lg lg:text-1xl xl:text-3xl text-left text-quater  font-light ",
    //         classHelp:"mt-10 w-5/12 text-quater font-bold ml-24 "
    //     },
    //     {
    //         classText1:"text-sm text-quater font-gmr sm:text-lg md:text-xl lg:text-2xl xl:text-5xl text-left  ml-24 my-2 ",
    //         classText2:"w-6/12 text-white text-left sm:text-lg md:text-xl lg:text-2xl xl:text-5xl ml-24 ",
    //         classText3:"text-sm font-gmr sm:text-md md:text-lg lg:text-1xl xl:text-3xl text-left text-quater  font-light  ",
    //         classHelp: "mt-10 w-5/12 text-quater  ml-24"
    //     },
    //     {
    //         classText1:"text-sm text-quater font-gmr sm:text-lg md:text-xl lg:text-2xl xl:text-5xl text-left  ml-24 my-2 ",
    //         classText2:"w-6/12 text-white text-left sm:text-lg md:text-xl lg:text-2xl xl:text-5xl ml-24 ",
    //         classText3:"text-sm font-gmr sm:text-md md:text-lg lg:text-1xl xl:text-3xl text-left text-quater text-white  font-light  ",
    //         classHelp: "mt-10 w-5/12 text-quater  ml-24"
    //     },
    // ];

    return (
        <div className={className}>
            <Swiper
                spaceBetween={10}
                effect={"fade"}
                navigation={true}
                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper"
                autoplay                
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <ImageSlide image={image} ></ImageSlide>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

