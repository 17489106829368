
import facebook from '../images/Footer/facebook.png';
import twitter from '../images/Footer/twitter.png';
import instagram from '../images/Footer/instagram.png';
// import back from '../images/Footer/footer.jpg';
import skylink from '../images/Footer/skylink.png';
import contact from '../images/Footer/CONTAC ICON_SKYLINE.svg';
import { useContext, useState } from 'react';
import { LangContext } from '../context/LangContext';
import { useForm } from '../hooks/useForm';
import axios from "axios"
import { sweetMessage } from '../helpers/sweetMessage';
import '../css/modal.css';
const url = 'contactus.php';
const formData = {
    name: '',
    email: '',
    body: '',
}
const formValidations = {
    name: [( value ) => value.length >= 1, 'El Nombre es obligatorio'],
    email: [( value ) => value.length >= 1, 'El Correo es obligatorio'],
    body: [( value ) => value.length >= 1, 'El Mensaje es obligatorio'],
}

export const ContactUs = () => {
    const [language] = useContext(LangContext);

    const [modalPrivacy, setModalPrivacy] = useState(false);
    const [modalCookies, setModalCookies] = useState(false);
    const [modalTerms, setModalTerms] = useState(false);
    const [modalFaq, setModalFaq] = useState(false);

    const [clickSubmit, setClickSubmit] = useState(false);
    const [formSubmited, setFormSubmited] = useState(false);
    const { 
        name, email, body, onInputChange,
        isFormValid, nameValid, emailValid, bodyValid, onResetForm
    } = useForm( formData, formValidations );
    
    const onSubmit = async( event ) => {
        event.preventDefault();
        setFormSubmited( true );
        if( !isFormValid || clickSubmit ) return;
        setClickSubmit( true );
        await axios.post(url, {name:name,email:email,body:body}, {headers: {
            'Content-Type': 'application/json',
        }})
        .then(res => {
            sweetMessage( language.mailtitle, 'success');
            onResetForm();
        })
        .catch(error => {
            sweetMessage( language.mailtitleerror, 'error');
        })
        .finally(() => {
            setClickSubmit( false );
        })
    }

    const closeAllModals = () => {
        setModalPrivacy(false);
        setModalCookies(false);
        setModalTerms(false);
        setModalFaq(false);
    }

  return (
    
    <form onSubmit={ onSubmit }>
        
        <div className='flex flex-col lg:flex-row h-full min-h-screen bg-quater'>

            <div className='bg-quater lg:w-1/2 flex flex-col text-white lg:pt-16 pb-5 justify-between'>

                <div className="px-10 md:px-16 lg:px-24 mt-10 lg:mt-0">
                    <p className='text-white font-gmr font-bold
                    text-xs xs:text-base sm:text-lg md:text-2xl lg:text-2xl xl:text-4xl'> { language.contact } </p>
                </div>

                <div className="px-10 md:px-16 lg:px-24 flex text-center lg:text-start mt-5 lg:mt-0">
                        <p className='font-raleway xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.footer1 } </p>
                </div>

                <div className="px-10 md:px-16 lg:px-24 hidden lg:block">
                        <img src={ contact } alt="Contacto" className='h-auto w-8 -ml-1 mb-5'/> 
                        <div className="-mt-12 ml-8">
                            <p className='font-raleway xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.footer2 } </p>
                        </div>
                </div>
                <div className="px-10 md:px-16 lg:px-24 hidden lg:block">
                    <p className='font-raleway text-primary xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.footer3 } </p>
                    <p className='font-raleway text-primary xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.tel } </p>
                </div>



                <div className="px-10 md:px-16 lg:px-24 flex-col hidden lg:flex">    
                    <div>
                        <a href="mailto:info@skylink-global.com?subject=Necesito información">
                            <button type='button' className="px-8 font-montmed text-white bg-primary rounded-[30px] 
                            hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                            xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs
                                xl:w-72 lg:w-64 md:w-72 w-52
                                lg:h-10 md:h-10 h-6">
                            { language.email }
                        </button></a>
                    </div>                
                    <div className='mt-3 inline-flex xl:w-72 lg:w-64 md:w-72 w-52 justify-around'>
                        <a href="https://www.facebook.com/profile.php?id=100064099068995"  rel="noreferrer" target='_blank'>
                        <img className="lg:w-10 md:w-10 w-8 mx-3"  src={facebook} alt="Facebook"/>
                        </a>
                        <img className="lg:w-10 md:w-10 w-8 mx-3" src={twitter} alt="Tuitter"/>
                        <a href="https://www.instagram.com/zendy_app/"  rel="noreferrer" target='_blank'>
                        <img className="lg:w-10 md:w-10 w-8 mx-3" src={instagram} alt="Instagram"/>
                        </a>
                    </div>
                </div>

                <div className="pl-24 my-5 flex flex-wrap"> 
                    <div className='w-1/2'>
                        <a href="#modalFaq" onClick={ () => setModalFaq(true) } className='font-montmed text-title text-xs'> {language.footerfaq}</a>
                    </div>  
                    <div className='w-1/2'>
                        <a href="#modalCookies" onClick={ () => setModalCookies(true) } className='font-montmed text-title text-xs'>{ language.footercookies }</a>
                    </div> 
                    <div className='w-1/2'>
                        <a href="#modalPrivacy" onClick={ () => setModalPrivacy(true) } className='font-montmed text-title text-xs'>{ language.footerprivacy }</a>
                    </div>
                    <div className='w-1/2'>
                        <a href="#modalTerms" onClick={ () => setModalTerms(true) } className='font-montmed text-title text-xs'> {language.footerterms}</a>
                    </div>
                </div>
                {/* <div className="pl-24 hidden lg:flex flex-wrap">
                        <a href="#modalFaq" onClick={ () => setModalFaq(true) } className='font-montmed text-title
                        text-xs mx-2'> {language.footerfaq}</a>
                        <a href="#modalPrivacy" onClick={ () => setModalPrivacy(true) } className='font-montmed text-title
                        text-xs mx-2'>{ language.footerprivacy }</a>
                        <a href="#modalCookies" onClick={ () => setModalCookies(true) } className='font-montmed text-title
                        text-xs mx-2'>{ language.footercookies }</a>
                        <a href="#modalTerms" onClick={ () => setModalTerms(true) } className='font-montmed text-title
                        text-xs mx-2'> {language.footerterms}</a>
                </div> */}
            </div>


            <div className="flex flex-col bg-cover bg-center bg-[url('./images/Footer/footer.jpg')] w-full lg:w-1/2 items-center pt-5 lg:pt-16 lg:h-full lg:min-h-screen">
                <div>
                    <img src={ skylink } alt="SKYLINK" className='xl:w-64 lg:w-48 w-52'/>
                </div>
                

                <div className='mx-10 my-5'>
                        <input type="text" name="name" id="name" value={name} onChange={ onInputChange } placeholder={ language.footernombre } autoComplete='off' style={{ backgroundColor: 'transparent' }}
                        className={"w-full rounded-lg border-2 "+ (!!nameValid && formSubmited ? "border-red" : "border-primary") +" py-1.5 px-4 text-white placeholder:text-white md:my-3 my-1"} />
                        
                        <input type="text" name="email" id="email" value={email} onChange={ onInputChange } placeholder={ language.footeremail } autoComplete='off' style={{ backgroundColor: 'transparent' }}
                        className={"w-full rounded-lg border-2 "+ (!!emailValid && formSubmited ? "border-red" : "border-primary") +" py-1.5 px-4 text-white placeholder:text-white md:my-3 my-1"} />
                        
                        <textarea name="body" id="body" value={body} onChange={ onInputChange } placeholder={ language.footermessage } autoComplete='off' style={{ backgroundColor: 'transparent' }}
                        className={"w-full rounded-lg border-2 "+ (!!bodyValid && formSubmited ? "border-red" : "border-primary") +" h-[100px] md:h-[200px] lg:h-[300px] py-1.5 px-4 text-white placeholder:text-white md:my-3 my-1"}></textarea>
                        <br />
                        <div className='grid justify-items-end'>
                            <button type="submit" className={"font-montmed text-white "+(clickSubmit ? "bg-title" : "bg-primary")+" rounded-[30px] "+(!clickSubmit && "hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title")+" lg:text-xs md:text-base text-xs lg:w-40 md:w-40 w-24 lg:h-8 md:h-8 h-6"}>
                                { language.footersend }
                            </button>
                        </div>
                </div>
            </div>

            <div className='bg-quater text-white justify-between flex flex-col lg:hidden grow pt-10'>
                <div className="px-10 md:px-16 lg:px-24 flex justify-center">
                        <div className="inline-flex">
                        <img src={ contact } alt="Contacto" className='h-auto w-8 -ml-1 mb-5 -mt-2 md:mt-0'/> 
                            <p className='font-raleway xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.footer2 } </p>
                        </div>
                </div>

                <div className="px-10 md:px-16 lg:px-24 text-center ">
                    <p className='font-raleway text-primary xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.footer3 } </p>
                    <p className='font-raleway text-primary xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs'> { language.tel } </p>
                </div>

                <div className="px-10 md:px-16 lg:px-24 flex-col flex text-center">    
                    <div className='my-5'>
                        <a href="mailto:info@skylink-global.com?subject=Necesito información">
                            <button type='button' className="px-8 font-montmed text-white bg-primary rounded-[30px] 
                            hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                            xl:text-xl lg:text-base md:text-lg sm:text-xs text-xs
                                xl:w-72 lg:w-64 md:w-72 w-52
                                lg:h-10 md:h-10 h-6">
                            { language.email }
                        </button></a>
                    </div>                
                    <div className='mt-3 inline-flex xl:w-72 lg:w-64 md:w-72 w-52 justify-around mx-auto'>
                        <a href="https://www.facebook.com/profile.php?id=100064099068995"  rel="noreferrer" target='_blank'>
                        <img className="lg:w-10 md:w-10 w-8 mx-3"  src={facebook} alt="Facebook"/>
                        </a>
                        <img className="lg:w-10 md:w-10 w-8 mx-3" src={twitter} alt="Tuitter"/>
                        <a href="https://www.instagram.com/zendy_app/"  rel="noreferrer" target='_blank'>
                        <img className="lg:w-10 md:w-10 w-8 mx-3" src={instagram} alt="Instagram"/>
                        </a>
                    </div>
                </div>

                <div className="text-center my-5 md:mb-10 flex flex-wrap md:flex-nowrap"> 
                    <div className='w-1/2'>
                        <a href="#modalFaq" onClick={ () => setModalFaq(true) } className='font-montmed text-title text-xs'> {language.footerfaq}</a>
                    </div>  
                    <div className='w-1/2'>
                        <a href="#modalCookies" onClick={ () => setModalCookies(true) } className='font-montmed text-title text-xs'>{ language.footercookies }</a>
                    </div> 
                    <div className='w-1/2'>
                        <a href="#modalPrivacy" onClick={ () => setModalPrivacy(true) } className='font-montmed text-title text-xs'>{ language.footerprivacy }</a>
                    </div>
                    <div className='w-1/2'>
                        <a href="#modalTerms" onClick={ () => setModalTerms(true) } className='font-montmed text-title text-xs'> {language.footerterms}</a>
                    </div>
                </div>

                {/* <div className="px-10 md:px-16 lg:px-24 text-center mb-5 md:mb-10 flex flex-col md:flex-row justify-center">  
                    <a href="#modalFaq" onClick={ () => setModalFaq(true) } className='font-montmed text-title
                    text-xs mx-5'> {language.footerfaq}</a>
                    <a href="#modalPrivacy" onClick={ () => setModalPrivacy(true) } className='font-montmed text-title
                    text-xs'>{ language.footerprivacy }</a>
                    <a href="#modalCookies" onClick={ () => setModalCookies(true) } className='font-montmed text-title
                    text-xs mx-5'>{ language.footercookies }</a>
                    <a href="#modalTerms" onClick={ () => setModalTerms(true) } className='font-montmed text-title
                    text-xs mx-5'> {language.footerterms}</a>
                </div> */}
            </div>
        </div>


        <div id="modalPrivacy" className={modalPrivacy ? "modalmask" : "hidden modalmask"}>
            <div className="modalbox movedown">
                <a href="#close" onClick={()=>closeAllModals()} title="Close" className="close">X</a>
                <iframe title='Privacy' name="iframe1" className='openweb' src="./texts/privacidad.html"></iframe>
            </div>
        </div>
        <div id="modalCookies" className={modalCookies ? "modalmask" : "hidden modalmask"}>
            <div className="modalbox movedown">
                <a href="#close" onClick={()=>closeAllModals()} title="Close" className="close">X</a>
                <iframe title='Cookies' name="iframe2" className='openweb' src="./texts/cookies.html"></iframe>
            </div>
        </div>
        <div id="modalTerms" className={modalTerms ? "modalmask" : "hidden modalmask"}>
            <div className="modalbox movedown">
                <a href="#close" onClick={()=>closeAllModals()} title="Close" className="close">X</a>                
                <iframe title='Terms' name="iframe3" className='openweb' src="./texts/terms.html"></iframe>
            </div>
        </div>        
        <div id="modalFaq" className={modalFaq ? "modalmask" : "hidden modalmask"}>
            <div className="modalbox movedown">
                <a href="#close" onClick={()=>closeAllModals()} title="Close" className="close">X</a>                
                <iframe title='Faq' name="iframe4" className='openweb' src="./texts/faq.html"></iframe>
            </div>
        </div>       
    </form>
  )
}
