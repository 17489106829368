import { NavBar } from '../components/NavBar'
import { ContactUs, Home, Products, AboutUs, Partners, Benefits, Investors} from '../components/'

export const HomePage = () => {
  // const wi = window.screen.width;
  // const he = window.screen.height;
  return (
    <>
      {/* wi: { wi } <br /> he: { he } <br /> */}
        <NavBar />
        <div id="home">
          <Home/>
        </div>
        <div id="about">
          <AboutUs />
        </div>
        <div id="benefits">
          <Benefits />
        </div>
        <div id="product">
          <Products />
        </div>
        <div id="partner">
          <Partners />
        </div>
        <div id="investor">
          <Investors />
        </div>
        <div id="contact">
          <ContactUs />
        </div>
    </>
  )
}
