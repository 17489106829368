

import { Navigation, EffectCoverflow, Autoplay   } from 'swiper/modules';
import SwiperCore from 'swiper'
import { Swiper, SwiperSlide  } from 'swiper/react';
import imgprevCel from '../images/Section5/prevCel.png';
import imgnextCel from '../images/Section5/nextCel.png';
import imgprev from '../images/Section5/prev.png';
import imgnext from '../images/Section5/next.png';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import '../css/style.css';
import { useContext, useRef, useState } from 'react';
import { LangContext } from '../context/LangContext';

export const Benefits = () => {
    const [language] = useContext(LangContext);
    
    let imgs = [];
    for(const images in language.slider){
        imgs.push(language.slider[images]);
    }
    const [indx, setIndx] = useState(0)

    const slider = (num) => {
        num = (num>(imgs.length-1))?0:((num<0?(imgs.length-1):num))
        setIndx(num)
    }

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    SwiperCore.use([Autoplay]);
    return (
        <>
            <div className='flex flex-col'>

                <div className='flex w-4/5 mx-auto pt-5 md:pt-16'>
                        <p className='text-quater font-gmr
                        lg:text-3xl md:text-3xl text-lg'> { language.secc5lang1 }</p>
                </div>


                <div>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={3}
                        loop={true}
                        scrollbar={{ draggable: true }}
                        autoplay={{
                            delay: 1000,
                            // disableOnInteraction: false,
                        }}
                        
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 100,
                            depth: 50,
                            modifier: 1,
                            scale: 1,
                            slideShadows: true,
                        }}
                        breakpoints={{
                            200: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            1024: {
                                slidesPerView: 3,
                            }
                        }}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        onSlideChange={(swiper) => slider(swiper.realIndex)}
                        modules={[EffectCoverflow, Navigation]}
                        className="mySwiper">
                        {
                            imgs.map(product =>  {
                                return ( 
                                    <SwiperSlide key={product.text}>
                                        <img src={product.src} alt={product.text} className='' />
                                    </SwiperSlide>             
                                )
                            })
                        }
                        <div className='flex text-center mt-10 lg:h-auto'>
                            <div ref={navigationPrevRef} className='w-1/4 hidden lg:flex items-center justify-center'>
                                <img src={ imgprev } alt="" className='md:inline hidden cursor-pointer'/>
                                <img src={ imgprevCel } alt="" className='md:hidden inline cursor-pointer'/>
                            </div>
                            <div className='w-full lg:w-2/4 h-44 lg:h-44 mx-10'>
                                <p className='font-gmr text-quater
                                    sm:text-lg md:text-2xl lg:text-2xl'>{ imgs[indx].text }</p>
                                <div className="flex flex-col justify-center text-center">
                                    <hr className="border-2 border-primary w-2/3 mx-auto my-7"/>
                                </div>
                                <p className='font-raleway text-senar
                                    lg:text-lg md:text-lg sm:text-sm text-xs'>{ imgs[indx].description }</p>
                            </div>
                            <div ref={navigationNextRef} className='w-1/4 hidden lg:flex items-center justify-center'>
                                <img src={ imgnext } alt="" className='md:inline hidden cursor-pointer'/>
                                <img src={ imgnextCel } alt="" className='md:hidden inline cursor-pointer'/>
                            </div>
                        </div>
                    </Swiper>
                </div>
            </div>
        </>
      );
}
